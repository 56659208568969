//@ts-check
import React from "react"
import NavbarLIP from "../components/LinkedInPowertools/NavbarLIP"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import AuthWrapper from "../components/AuthWrapper"

import GetStartedMembership from "../components/LinkedInPowertools/GetStartedMembership"

const PaymentSuccess = () => {
  return (
    <AuthWrapper>
      <Layout>
        <NavbarLIP />

        <GetStartedMembership />

        <Footer />
      </Layout>
    </AuthWrapper>
  )
}

export default PaymentSuccess
