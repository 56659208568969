//@ts-check
import React from "react"

import { Link } from "gatsby"

import StarIcon from "../../assets/images/star-icon.png"
import HowItsWork from "../../assets/images/how-its-work.png"

const GetStartedMembership = () => {
  return (
    <section className="how-its-work-area ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-content">
              <h2>Payment Successful</h2>
              <span className="sub-title" style={{ fontSize: "30px" }}>
                <img src={StarIcon} alt="image" />
                Lets Get Started!
              </span>
              <p>Three simple steps to get started!</p>
              <div className="inner-box">
                <div className="single-item">
                  <div className="count-box">1</div>
                  <h3>Download the chrome extension </h3>
                  <div className="btn-box">
                    <Link
                      to="https://chrome.google.com/webstore/detail/linkedin-powertools/ebbpbbdigdenlglbdlpnbbonofdaahlf"
                      className="default-btn"
                    >
                      <i className="flaticon-tick"></i>
                      Download Here
                      <span></span>
                    </Link>
                  </div>
                  <p>
                    Once you've installed that, log in and start commenting away
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">2</div>
                  <h3>Start Commenting</h3>
                  <p>
                    To start using the tool, just open LinkedIn, and go to
                    comment on a post. You should see a little{" "}
                    <span style={{ color: "forestgreen" }}>"LiPow"</span>{" "}
                    button. Click that and wait for a few seconds. If you get an
                    error, just open the chrome extension and make sure that
                    you're logged in
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">3</div>
                  <h3>Set a Schedule</h3>
                  <p>
                    Remembering to comment before your post goes live is hard.
                    You can set a reminder in the extension for each day. You'll
                    then get a notification 30 minutes before
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-image">
              <img src={HowItsWork} alt="image" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default GetStartedMembership
